<template>
  <div class="card mb-7">
    <div class="card-body">
      <!--begin::Title-->
      <h3 class="mb-5">
        {{!newcard ? $t(`${currentLanguage}.profile.billing.my_cards`) : $t(`${currentLanguage}.profile.billing.add_new_card`)}}
      </h3>
      <!--end::Title-->

      <!-- begin::AddNewCard -->
        <payment-add-new-card v-if="newcard" @cancel="closeNewCard()" @finish="reload()"/>
      <!-- end::AddNewCard -->

      <!--begin::Row-->
      <div class="row gx-9 gy-6" v-if="!newcard">
        <!-- begin: Payment Cards -->
        <payment-card v-for="card in cards" :key="card.id" :data="card" @delete="deleteCard"/>
        <!-- end: Payment Cards -->

        <!--begin::Col-->
        <div class="col-xl-6" style="min-height: 139.95px">
          <!--begin::Notice-->
          <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6">
            <!--begin::Wrapper-->
            <div class="d-flex flex-grow-1 flex-wrap flex-md-nowrap justify-content-center">
              <!--begin::Action-->
              <a href="#" class="btn btn-primary px-6 align-self-center text-nowrap" @click="addNewCard()">
                {{$t(`${currentLanguage}.profile.billing.add_card`)}}
              </a>
              <!--end::Action-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Notice-->
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>
  </div>
</template>

<script>
import PaymentCard from "@/views/crafted/subscription/creditcard/Subscription-Card.vue";
import PaymentAddNewCard from "@/views/crafted/subscription/creditcard/Subscription-AddNewCard.vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {useStore} from "vuex";
import {computed} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "Subscription-CreditCard",
  setup(){
    const { t } = useI18n();
    const store = useStore();
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));
    return {
      currentLanguage
    }
  },
  components:{
    PaymentCard,
    PaymentAddNewCard
  },
  data(){
    return {
      newcard: false,
      cards: [],
    }
  },
  methods:{
    addNewCard() {
      this.newcard=true
    },
    closeNewCard() {
      this.newcard=false
    },
    async reload(){
      try{
        const cardsRepsonse = await ApiService.get('payment/index')
        this.cards = cardsRepsonse.data
        this.closeNewCard()
      }catch(e){
        console.error(e)
      }
    },
    deleteCard(card){
      Swal.fire({
        title: this.$t(`${this.currentLanguage.value}.profile.billing.add_card`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t(`${this.currentLanguage.value}.common.yes`),
        cancelButtonText: this.$t(`${this.currentLanguage.value}.common.no`)
      }).then((result)=>{
        if(result.isConfirmed){
          ApiService.delete(`payment/destroy/${card.id}`).then(response=>{
            Swal.fire({
              title:'The card has been deleted',
              icon: 'success',
              confirmButtonText: 'Ok'
            })
            this.reload()
          })
        }
      })
    }
  },
  async mounted() {
    try{
      const cardsRepsonse = await ApiService.get('payment/index')
      this.cards = cardsRepsonse.data
    }catch(e){
      console.error(e)
    }
  }
}
</script>

<style scoped>

</style>