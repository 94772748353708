
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import CheckoutMain from "@/views/crafted/checkout/CheckoutMain.vue";
import SubscriptionMain from "@/views/crafted/subscription/SubscriptionMain.vue";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "Account-Billing",
  data(){
    return {
      loading: true
    }
  },
  components: {
    SubscriptionMain,
    CheckoutMain
  },
  setup() {
    const store = useStore();
    store.dispatch(Actions.VERIFY_AUTH);
    const user = computed(() => store.getters.currentUser);
    let orders = ref([]);
    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));


    moment.locale('pt-br')

    return {
      user,
      orders,
      moment,
      currentLanguage
    };
  },
  watch : {
    user: {
      handler: function (val) {
        
        if (val) {
          this.loading = false
        }
      },
      deep: true
    }
  }
});
