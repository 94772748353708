<template>
  <!--begin::Col-->
  <div class="col-xl-6" data-kt-billing-element="card">
    <!--begin::Card-->
    <div class="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6 cursor-pointer hover-card-bg" :class="{'border-success':selected, 'bg-light-success': selected}">
      <!--begin::Info-->
      <div class="d-flex flex-column py-2">
        <!--begin::Owner-->
        <div class="d-flex align-items-center fs-4 fw-bold mb-5">
          {{data.holder_name}}
          <span class="badge badge-light-success fs-7 ms-2" v-if="data.default">Default</span>
        </div>
        <!--end::Owner-->

        <!--begin::Wrapper-->
        <div class="d-flex align-items-center">
          <!--begin::Details-->
          <div>
            <div class="fs-4 fw-bold">{{maskedNumbers}}</div>
            <div class="fs-6 fw-semibold text-gray-500">Expires in {{padMonth}}/{{data.year}}</div>
          </div>
          <!--end::Details-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Info-->

      <!--begin::Actions-->
      <div class="d-flex align-items-center py-2">
        <button class="btn btn-sm btn-light btn-active-light-primary me-3" data-kt-billing-action="card-delete" @click.stop="$emit('delete', {id:data.id})">

          <!--begin::Indicator label-->
          <span class="indicator-label">
              Delete
          </span>
          <!--end::Indicator label-->

          <!--begin::Indicator progress-->
          <span class="indicator-progress">
            Wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
          <!--end::Indicator progress-->
        </button>
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Card-->
  </div>
  <!--end::Col-->
</template>

<script>
export default {
  name: "Payment-Card",
  props:{
    selected: Boolean,
    data: Object
  },
  computed:{
    maskedNumbers() {
      return this.data.display_number.replaceAll('X', '*').replaceAll('-', ' ')
    },
    padMonth(){
      return String(this.data.month).padStart(2, '0')
    }
  }
}
</script>

<style scoped>
.hover-card-bg:hover{
  background-color: #1a1a26;
}
</style>